import { useEffect, useState, ChangeEvent } from "react"
// import RaceTable, { RaceObj } from "./components/RaceTable"\
import { liveRaceDetailAPI, liveRacesAPI, getRawDataAPI } from "api"
import Card from "components/card"
import Select, {MultiValue,StylesConfig } from 'react-select';
import 'react-select-search/style.css'
import InputField from "components/fields/InputField";
import optionList from 'data/option_list.json'
import chroma from 'chroma-js';
import moment from 'moment-timezone';
import { stringify } from "querystring";
import { filter } from "@chakra-ui/system";
import { get } from "http";
// import HorseTable from "./components/HorseTable"
export type RowObj = {
    id:string,
    state: string;
    racecourse: string;
    no: number;
    start_at: string;
    name: string;
    status: string;
    type: string;
    weather:string;
    length: number;
    horses?: Array<SelectionObj>;
    marketStatus?: string;
    inPlay: boolean;
    stake: string;
    numberOfRunners: number;
  };
export type Odd = {
    price: number,
    size: number
}
export type SelectionObj = {
    [key: string]: any;
    selection:number,
    tab:string,
    horse:string,
    jockey:string,
    rat: string,
    bp_adj: string,
    wgt:string,
    hi_wt:string,
    p:string,
    afs:string,
    pp:string,
    uo:string,
    ct: number,
    rts: number,
    back_odds:Array<Odd>,
    lay_odds: Array<Odd>,
    placing: string,
    placedResult: number,
    rf_prediction: string,
    rf_prediction_1min: string,
    rf_prediction_2min: string,
    rf_prediction_3min: string,
}
  
const LiveRace = () => {
    const [raceData, setRaceData] = useState<Array<RowObj>>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [showModal, setShowModal] = useState<boolean>(false)
    const [selectedRace, setSelectedRace] = useState<RowObj>(null)
    const [timerId, setTimerId] = useState<NodeJS.Timeout>(null)
    const [curerntTime, setCurrentTime] = useState<Date>(null)
    const [horseRunning, setHorseRunning] = useState<MultiValue<OptionType>>([])
    const [trackGTELength, setTrackGTELength] = useState<number>(0)
    const [trackLTELength, setTrackLTELength] = useState<number>(0)
    const [raceCourseTypeGroup, setRaceCourseTypeGroup] = useState<string>("group")
    const [sortByTime, setSortByTime] = useState<boolean>(false)
    const [trackType, setTrackType] = useState<MultiValue<OptionType>>([])
    const [raceType, setRaceType] = useState<MultiValue<OptionType>>([])
    const [stateAU, setStateAU] = useState<MultiValue<OptionType>>([])
    const [stateHorses, setStateHorses] = useState<Array<SelectionObj>>([])
    const [pSelect, setPSelect] = useState<MultiValue<OptionType>>([])

    const date = new Date(new Date().toLocaleString(undefined, {
        timeZone: "Australia/Sydney"
    }))
        
    interface OptionType {
        value: string;
        label: string;
    }
    interface ColourOption {
        readonly value: string;
        readonly label: string;
        readonly color: string;
        readonly isFixed?: boolean;
        readonly isDisabled?: boolean;
    }

    interface LengthOption {
        over: Number,
        under: Number
    }

    interface FilterOption {
        pp: LengthOption
    }
  
    const colourOptions: OptionType[] = [
        { value: 'MDN', label: 'MDN'},
        { value: 'CL1', label: 'CL1'},
        { value: 'CL2', label: 'CL2'},
        { value: 'CL3', label: 'CL3'},
        { value: 'CL4', label: 'CL4'},
        { value: 'CL5', label: 'CL5'},
        { value: 'CL6', label: 'CL6'},
        { value: '2U', label: '2U'},
        { value: '3O', label: '3O'},
        { value: 'FM', label: 'FM'},
        { value: 'BM34-70', label: 'BM34-70'},
        { value: 'BM71-90', label: 'BM71-90'},
        { value: 'HDL & STLE', label: 'HDL & STLE'},
        { value: 'GROUPS', label: 'GROUPS'},
    ];
  
    const selectionOptions : OptionType[]  = optionList.horseRunningList

    const stateOptions : OptionType[]  = optionList.stateList
    const raceCourseOptions : OptionType[]  = optionList.raceCourseList
    const raceTypeOptions : OptionType[]  = optionList.raceTypeList
    const trackTypeOptions : OptionType[]  = optionList.trackTypeList
    const horseRunningOptions : OptionType[]  = optionList.horseRunningList
    const statePOption : OptionType[]  = optionList.pOption

    const changeSort = (e: ChangeEvent<HTMLInputElement>) => {
        setSortByTime(e.target.checked)
    }

    const getSorted = () => {
        if(stateHorses) {
            const afsList = stateHorses.map(horse => {
                if (horse.afs){
                    return Number(horse.afs)
                } else {
                    return 0
                }
            })
            const uniq = [...new Set(afsList)];
            //Sort the array from lowest to highest
            //Thanks to Charlietfl, I forgot to sort it numerically
            uniq.sort(function(a, b) {
                return b - a;
            });
        
            return uniq
        } else {
            return []
        }

    }

    const getColor  = (value: string) => {
        let color: string = "#333333"
        // 1. filter for "MDN"
        if (value.indexOf("MDN") >=0 ){
        color = "#FF0000"
        return color
        }

        if( value.indexOf("CL1") >=0) {
        color="#0000FF"
        return color
        }

        if( value.indexOf("CL2") >=0) {
        color="#00FF00"
        return color
        }

        if( value.indexOf("CL3") >=0) {
        color="#4B0082"
        return color
        }

        if( value.indexOf("CL4") >=0) {
        color="#FFA500"
        return color
        }

        if( value.indexOf("CL5") >=0) {
        color="#800080"
        return color
        }

        if( value.indexOf("CL5") >=0) {
        color="#FFC0CB"
        return color
        }

        if( value.indexOf("CL7") >=0) {
        color="#A52A2A"
        return color
        }

        if( value.indexOf("2U") >=0) {
        color="#00FFFF"
        return color
        }

        if( value.indexOf("3O") >=0) {
        color="#FF00FF"
        return color
        }
        
        if( value.indexOf("FM") >=0) {
        color="#008080"
        return color
        }

        if( value.indexOf("BM") >=0) {
        color="#40E0D0"
        return color
        }

        if( value.indexOf("OPEN") >=0) {
        color="#800000"
        return color
        }

        if( value.indexOf("HDL") >=0) {
        color="#000080"
        return color
        }

        if( value.indexOf("G") >=0) {
        color="#FFD700"
        return color
        }
        return color
    } 
  const colourStyles: StylesConfig<OptionType, true> = {
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    
      const dataColor = getColor(data.value)

      // 1. Filter for "MDN"
      

      const color = chroma(dataColor);
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? dataColor
          : isFocused
          ? color.alpha(0.1).css()
          : undefined,
        color: isDisabled
          ? '#ccc'
          : isSelected
          ? chroma.contrast(color, 'white') > 2
            ? 'white'
            : 'black'
          : dataColor,
        cursor: isDisabled ? 'not-allowed' : 'default',
  
        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
              ? dataColor
              : color.alpha(0.3).css()
            : undefined,
        },
      };
    },
    multiValue: (styles, { data }) => {
      const dataColor = getColor(data.value)
      const color = chroma(dataColor);
      return {
        ...styles,
        backgroundColor: color.alpha(0.1).css(),
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color:  getColor(data.value),
    }),
    multiValueRemove: (styles, { data }) => ({
    
      ...styles,
      color:  getColor(data.value),
      ':hover': {
        backgroundColor:  getColor(data.value),
        color: 'white',
      },
    }),
  };

  const handleTrackGTELengthChange = (e:ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setTrackGTELength(parseInt(e.currentTarget.value || "0"))
  }

  const handleTrackLTELengthChange = (e:ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setTrackLTELength(parseInt(e.currentTarget.value || "0"))
  }

  const handleGroupChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRaceCourseTypeGroup(e.currentTarget.value)
    setRaceType([])
    console.log(raceCourseTypeGroup)
  }

    const getData = () => {
        const sort = (document.getElementById("sort-by-time") as HTMLInputElement)?.checked
        const data = {
            state:stateAU?.map(el=>el.value),
            raceType:raceType?.map(el=>el.value),
            raceTypeGroup:raceCourseTypeGroup === "group",
            trackType:trackType?.map(el=>el.value),
            horseRunning: horseRunning?.map(el=>Number(el.value)),
            trackGTELength:trackGTELength,
            trackLTELength:trackLTELength,
            sortByTime:sort
          }
        liveRacesAPI(data).then(res => {
            const data = res.result.map((el : any) => {
                let obj : RowObj = {
                    state: el.state,
                    racecourse:el.racecourse,
                    no:el.no,
                    start_at:el.start_at,
                    name:el.name,
                    status:el.status,
                    type:el.type,
                    weather:el.weather+"-"+el.stat,
                    length:el.length,
                    stake:el.stake,
                    marketStatus:el.marketStatus,
                    inPlay:el.inplay,
                    id:el._id,
                    numberOfRunners:el.horses.length,
                    horses:el.horses,
                }
                
                return obj
            })
            setRaceData(data)

        })
    }

    const raceMapper = {
        state:"State",
        racecourse:"Racecourse",
        no:"Race No",
        start_at:"Start Time",
        name:"Race Name",
        status:"Turf",
        type:"Track Type",
        weather:"Weather",
        length:"Length",
        stake:"Total Stake",
        marketStatus:"Market Status",
        id:"",
        numberOfRunners: "Total Runners",
    }

    const selectionMapper = {
        selection: "Selection",
        tab:"TAB",
        horse:"Horse Name",
        jockey:"Jockey Name",
        rat:"Rat",
        bp_adj:"Barrier",
        wgt:"Weight",
        hi_wt:'WWW',
        p: 'RP',
        afs: 'AFS',
        pp:"PP",
        uo:"U/O",
        ct:"CT",
        rts:"RTS",
        back_odds:"Back Odds",
        lay_odds: "Lay Odds",
        placing: "Placing",
        placedResult: "PlacedResult",
        rf_prediction: "RFP",
        rf_prediction_1min: "RFP(1min)",
        rf_prediction_2min: "RFP(2min)",
        rf_prediction_3min: "RFP(3min)",
    }
  
    const getRaceData = (id:string, repeat: boolean) => {
        const data = {
            id: id
        }

        const row = raceData.find(el => el.id ===id)
        if(row){
            setLoading(true)
            liveRaceDetailAPI(data).then(res => {
                // console.log(res)
                if(res){
                    let horses : SelectionObj[] = []

                    setStateHorses(res.result.horses);

                    // restrict filter
                    let ppGteLength = parseFloat((document.getElementById('ppGteLength') as HTMLInputElement)?.value)
                    let ppLteLength = parseFloat((document.getElementById('ppLteLength') as HTMLInputElement)?.value)
                    let prGteLength = parseFloat((document.getElementById('prGteLength') as HTMLInputElement)?.value)
                    let prLteLength = parseFloat((document.getElementById('prLteLength') as HTMLInputElement)?.value)
                    let rlwGteLength = parseFloat((document.getElementById('rlwGteLength') as HTMLInputElement)?.value)
                    let rlwLteLength = parseFloat((document.getElementById('rlwLteLength') as HTMLInputElement)?.value)
                    let wgtGteLength = parseFloat((document.getElementById('wgtGteLength') as HTMLInputElement)?.value)
                    let wgtLteLength = parseFloat((document.getElementById('wgtLteLength') as HTMLInputElement)?.value)
                    let afsGteLength = parseFloat((document.getElementById('afsGteLength') as HTMLInputElement)?.value)
                    let afsLteLength = parseFloat((document.getElementById('afsLteLength') as HTMLInputElement)?.value)
                    let lsratGteLength = parseFloat((document.getElementById('lsratGteLength') as HTMLInputElement)?.value)
                    let lsratLteLength = parseFloat((document.getElementById('lsratLteLength') as HTMLInputElement)?.value)
                    let ratGteLength = parseFloat((document.getElementById('ratGteLength') as HTMLInputElement)?.value)
                    let ratLteLength = parseFloat((document.getElementById('ratLteLength') as HTMLInputElement)?.value)
                    let formGteLength = parseFloat((document.getElementById('formGteLength') as HTMLInputElement)?.value)
                    let formLteLength = parseFloat((document.getElementById('formLteLength') as HTMLInputElement)?.value)
                    let backpriceGteLength = parseFloat((document.getElementById('backpriceGteLength') as HTMLInputElement)?.value)
                    let backpriceLteLength = parseFloat((document.getElementById('backpriceLteLength') as HTMLInputElement)?.value)

                    let pSelectArray = pSelect.map(item => item.value);

                    let totalRFPrediction = 0;
                    let totalRFPrediction_1min = 0;
                    let totalRFPrediction_2min = 0;
                    let totalRFPrediction_3min = 0;

                    for (let i = 0; i < res.result.horses.length; i++) {
                        totalRFPrediction += res.result.horses[i].rf_prediction;
                        totalRFPrediction_1min += res.result.horses[i].rf_prediction_1min;
                        totalRFPrediction_2min += res.result.horses[i].rf_prediction_2min;
                        totalRFPrediction_3min += res.result.horses[i].rf_prediction_3min;
                    }

                    res.result.horses.filter((el:any, idx: number)=> {
                        if(el.pp !== 999) {
                            let uo = ''
                            if (el.back_odds.length > 0) {
                                if (el.back_odds[0].price <= el.pp) {
                                    uo = 'U'
                                } else {
                                    uo = 'O'
                                }
                            }

                            let rts = 0


                            let object : SelectionObj = {
                                selection:idx+1,
                                tab:el.tab,
                                horse:el.horse,
                                jockey:el.jockey,
                                rat: el.rat,
                                bp_adj:el.bp_adj,
                                wgt:el.wgt,
                                hi_wt:el.hi_wt,
                                p:el.p,
                                afs:el.afs,
                                pp:el.pp,
                                uo:uo,
                                ct: el.ct,
                                rts: rts,
                                back_odds:el.back_odds,
                                lay_odds: el.lay_odds,
                                placing: el.placing,
                                placedResult: el.placedResult,
                                rf_prediction: parseFloat((el.rf_prediction / totalRFPrediction*100).toFixed(1)) + '%',
                                rf_prediction_1min: parseFloat((el.rf_prediction_1min / totalRFPrediction_1min * 100).toFixed(1)) + '%',
                                rf_prediction_2min: parseFloat((el.rf_prediction_2min / totalRFPrediction_2min * 100).toFixed(1)) + '%',
                                rf_prediction_3min: parseFloat((el.rf_prediction_3min / totalRFPrediction_3min * 100).toFixed(1)) + '%'
                            }

                            let new_object : any = {
                                selection:idx+1,
                                tab:getCellData(object, 'tab'),
                                horse:getCellData(object, 'horse'),
                                jockey:getCellData(object, 'jockey'),
                                rat: getCellData(object, 'rat'),
                                bp_adj:getCellData(object, 'bp_adj'),
                                wgt:getCellData(object, 'wgt'),
                                hi_wt:getCellData(object, 'hi_wt'),
                                p:getCellData(object, 'p'),
                                afs:getCellData(object, 'afs'),
                                pp:getCellData(object, 'pp'),
                                uo:getCellData(object, 'uo'),
                                ct:getCellData(object, 'ct'),
                                rts:getCellData(object, 'rts'),
                                back_odds:getCellData(object, 'back_odds'),
                                lay_odds: getCellData(object, 'lay_odds'),
                                placing:getCellData(object, 'placing'),
                                placedResult:getCellData(object, 'placedResult'),
                                ml_placing: getCellData(object,'ml_placing'),
                                rf_placing: getCellData(object, 'rf_placing'),
                                rf_prediction: getCellData(object, 'rf_prediction'),
                                rf_prediction_1min: getCellData(object, 'rf_prediction_1min'),
                                rf_prediction_2min: getCellData(object, 'rf_prediction_2min'),
                                rf_prediction_3min: getCellData(object, 'rf_prediction_3min'),
                            }

                            let form_l5 = el.form_l5.match(/\d+/)?.[0] ? parseFloat(el.form_l5.match(/\d+/)?.[0]) : 0;

                            if ((ppGteLength && parseFloat(new_object.pp) < ppGteLength) || (ppLteLength && parseFloat(new_object.pp) > ppLteLength)) {
                                return false
                            }
                            if ((prGteLength && parseFloat(el.pr) < prGteLength) || (prLteLength && parseFloat(el.pr) > prLteLength)) {
                                return false
                            }
                            if ((rlwGteLength && parseFloat(el.rlw) < rlwGteLength) || (rlwLteLength && parseFloat(el.rlw) > rlwLteLength)) {
                                return false
                            }
                            if ((wgtGteLength && parseFloat(new_object.wgt) < wgtGteLength) || (wgtLteLength && parseFloat(new_object.wgt) > wgtLteLength)) {
                                return false
                            }
                            if ((afsGteLength && parseFloat(new_object.afs) < afsGteLength) || (afsLteLength && parseFloat(new_object.afs) > afsLteLength)) {
                                return false
                            }
                            if ((lsratGteLength && parseFloat(el.ls_rat) < lsratGteLength) || (lsratLteLength && parseFloat(el.ls_rat) > lsratLteLength)) {
                                return false
                            }
                            if ((ratGteLength && parseFloat(el.rat) < ratGteLength) || (ratLteLength && parseFloat(el.rat) > ratLteLength)) {
                                return false
                            }
                            if ((backpriceGteLength && parseFloat(new_object.back_odds) < backpriceGteLength) || (backpriceLteLength && parseFloat(new_object.back_odds) > backpriceLteLength)) {
                                return false
                            }
                            if ((formGteLength && form_l5 < formGteLength) || (formLteLength && form_l5 > formLteLength)) {
                                return false
                            }
                            if (pSelectArray.length > 0 && !pSelectArray.includes(new_object.p)) {
                                return false
                            }

                            horses.push(new_object)
                        }
                    })
                    
                    let obj : RowObj = {
                        state: res.result.state,
                        racecourse:res.result.racecourse,
                        no:res.result.no,
                        start_at:res.result.start_at,
                        name:res.result.name,
                        status:res.result.status,
                        type:res.result.type,
                        weather:res.result.weather,
                        horses: horses,
                        length:res.result.length,
                        stake: res.result.stake,
                        marketStatus:res.result.marketStatus,
                        inPlay:res.result.inplay,
                        numberOfRunners:horses.length,
                        id:res.result._id
                    }

                    setSelectedRace(obj)
                    if(!repeat){
                        setSelectedRace(obj)
                        setShowModal(true)
                    } else {
                        if(selectedRace.id === obj.id){
                            setSelectedRace(obj)
                        }
                    }
                    setLoading(false)

                }
            })
        }
    }

    const getRawData = () => {
        getRawDataAPI({date: null})
    }

    useEffect(()=> {
        const timer = setInterval(()=>{
            getData()
        }, 1000*2)

        const clock = setInterval(()=>{
            let d = new Date();
            setCurrentTime(d)
        }, 1000)
        getData()
    }, [])


    const getCellData = (row : SelectionObj, el : string) => {
        console.log(row)
        let result;
        const element = row[el as keyof typeof row]

        switch (el){
            case 'back_odds':
            case 'lay_odds':
                const temp = element as Array<Odd>
                if(temp && temp.length > 0){ 
                    result = temp[0].price.toFixed(2)
                } else {
                    result = ""
                }
                break;
            case 'pp':
            case 'wgt':
                result = element as string
                result = parseFloat(result).toFixed(2)
                break;
            case 'hi_wt':
                result = element as string
                if(!isNaN(Number(result))){
                    try {
                        result = (Number(row.wgt) - Number(result)).toFixed(1)
                    } catch (e){
                        result = "-"
                    }
                } else {
                    result = "-"
                }
                break;
            case 'rts':
                result = element as string
                if (isNaN(Number(row.hi_wt))) {
                    result = Number(row.rat) + Number(row.wgt) + Number(row.ct)
                } else {
                    result = Number(row.rat) + 2* Number(row.wgt) - Number(row.hi_wt)  + Number(row.ct)
                }
                break;
            case 'placing':
                result = element as string
                if(result === 'REMOVED'){
                    result ='SCRATCHED'
                }
                break;
            case 'placedResult':
                result = element as string
                if(result === '' || result === '0' ){
                    result ='-'
                }
                break;
            case 'afs':
                result = element as string
                let sortedList = getSorted()

                if(result) {
                    const idx = sortedList.indexOf(Number(result))
                    result = (idx >=0 ?idx + 1 : 1).toString()
                } else {
                    result = (sortedList.length + 1).toString()
                }
                break;
            default:
                result = element as string
                break;
        }

        return result
    }

    const getCellClass = (row : SelectionObj, el : string) => {
        let result = "border-white/0 py-3  pr-4 text-center "
        const element = row[el as keyof typeof row]

        let temp;
        switch (el) {
            case 'rat':
                temp = element as number
                if(temp >= 5){
                    result += "bg-yellow-200 "
                }
                break;
            case 'bp_adj':
                temp = element as string
                if(Number(temp) >= 10){
                    result += "bg-yellow-200 "
                }
                break;
            case 'wgt':
                temp = element as string
                if(Number(temp) >= 59){
                    result += "bg-yellow-200 "
                }
                break;
            case 'hi_wt':
                temp = element as string
                if(!isNaN(Number(temp))){
                    try {
                        // temp = (Number(row.wgt) - Number(temp))
                        if(Number(temp) >= 2){
                            result += "bg-yellow-200 "
                        }
                    } catch (e){
                        // result += "bg-yellow-200 "
                    }
                } else {
                    // result += "bg-yellow-200 "
                }
                break;
            case 'afs':
                temp = element as string
                let sortedList = getSorted()
                if(temp){
                    const idx = sortedList.indexOf(Number(temp))

                    temp = (idx >=0 ?idx + 1 : 1)
                } else {
                    temp = (sortedList.length + 1)
                }
                if(temp >= 5){
                    result += "bg-yellow-200 "
                }
                break; 
            case 'p':
                temp = element as string
                if(temp==="OM" || temp === "BK"){
                    result += "bg-yellow-200 "
                }
                break;
            case 'placing':
                temp = element as string
                if(temp==="WINNER"){
                    result += "bg-green-200 "
                }
                break; 
        }
        return result
    }
    
    const closeModal = () => {
        clearInterval(timerId)
        setTimerId(null)
        setShowModal(false)

    }
    const pad = (num: number)=> {
        return (num < 10 ? '0' : '') + num;
    };

    const  msToTime = (duration:number) => {
        let milliseconds = Math.floor((duration % 1000) / 100),
          seconds = Math.floor((duration / 1000) % 60),
          minutes = Math.floor((duration / (1000 * 60)) % 60),
          hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
      
        let hours_str = (hours < 10) ? "0" + hours : hours.toString();
        let minutes_str = (minutes < 10) ? "0" + minutes : minutes.toString();
        let seconds_str = (seconds < 10) ? "0" + seconds : seconds.toString();
      
        return hours_str + ":" + minutes_str + ":" + seconds_str;
    }
    
    const getDate = (hours:string) => {
        try{
            
            const raceDate = new Date(`${date.getFullYear()}-${pad(date.getMonth()+1)}-${pad(date.getDate())}T${hours}:00+10:00`)
            const sydneyDate = moment.tz(`${date.getFullYear()}-${pad(date.getMonth()+1)}-${pad(date.getDate())}T${hours}`, "Australia/Sydney").toDate();
            // console.log(raceDate, sydneyDate)
            const delta = sydneyDate.getTime() - curerntTime.getTime()
            if (delta < 0){
                if (delta < -1200*1000) return hours
                else {
                    return "-"+msToTime(Math.abs(delta))
                }
            } else {
                if ( delta > 3600*1000) return hours
                else {
                    return msToTime(delta)
                }
            }
        } catch{
            return "00:00:00"
        }
    }

    useEffect(()=>{
        if(showModal){
            const timer = setInterval(()=> {
                getRaceData(selectedRace.id, true)
            }, 1000*2)
            setTimerId(timer)

        } else {
            closeModal()
        }
    }, [showModal])

    return (
        <>
        <div className="mt-5 grid h-full grid-cols-1 gap-5 mb-5 bg-white p-2">
            <div>
                <div className="m-2">
                    <h6 className="text-xl font-bold text-navy-700 dark:text-white">Track Filters</h6>
                </div>

                <div className="mt-2 grid grid-cols-5 gap-2">
                    <label className="col-span-1 text-center flex items-center justify-center">State:</label>
                    <Select 
                        value={stateAU} 
                        onChange={setStateAU}
                        isMulti
                        options={stateOptions}
                        className="col-span-3" 
                    />
                </div>
                <div className="mt-2 grid grid-cols-5 gap-2">
                    <label className="col-span-1 text-center flex items-center justify-center">Race Course Type:</label>
                    <div className="col-span-3 gap-2 grid grid-cols-4">
                        <div className="col-span-1 flex items-center">
                            <label>group</label>
                            <input type="radio" checked={raceCourseTypeGroup === "group"} value="group" onChange={handleGroupChange} name="raceCourseTypeGroup" className="ml-2"/>
                        </div>
                        <div className="col-span-1 flex items-center">
                            <label>individual</label>
                            <input type="radio" checked={raceCourseTypeGroup ==="individual"} value="individual" onChange={handleGroupChange} name="raceCourseTypeGroup" className="ml-2"/>
                        </div>
                    </div>
                </div>
                <div className="mt-2 grid grid-cols-5 gap-2">
                <label className="col-span-1 text-center flex items-center justify-center"></label>

                <Select 
                    value={raceType} 
                    onChange={setRaceType}
                    isMulti
                    styles={colourStyles}
                    options={raceCourseTypeGroup === "group" ?colourOptions: raceTypeOptions}
                    // options={ColourOption} 
                    className="col-span-3" 
                />
                </div>
                <div className="mt-2 grid grid-cols-5 gap-2">
                    <label className="col-span-1 text-center flex items-center justify-center">Track Type:</label>
                    <Select 
                        value={trackType} 
                        onChange={setTrackType}
                        isMulti
                        options={trackTypeOptions} 
                        className="col-span-3" 
                    />
                </div>
                <div className="mt-2 grid grid-cols-5 gap-2">
                <label className="col-span-1 text-center flex items-center justify-center">Horse Running:</label>
                <Select 
                    value={horseRunning} 
                    onChange={setHorseRunning}
                    isMulti
                    options={horseRunningOptions} 
                    className="col-span-3" 
                    />
                </div>
                <div className="mt-2 grid grid-cols-5 gap-2">
                <label className="col-span-1 text-center flex items-center justify-center">Sort By Time:</label>
                <input type="checkbox" id="sort-by-time" onChange={changeSort} ></input>
                </div>
                <div className="mt-5 grid  grid-cols-7 gap-5 bg-white p-5 rounded-lg" >
                {/* FromSelection */}
                <InputField
                    variant="auth"
                    extra="mb-3"
                    label="Length Over:"
                    placeholder="ex: 1"
                    id="gteLength"
                    state="success"
                    type="number"
                    onChangeHandler={handleTrackGTELengthChange}
                />
                {/* ToSelection */}
                <InputField
                    variant="auth"
                    extra="mb-3"
                    label="Length Under:"
                    placeholder="ex: 5"
                    id="lteLength"
                    state="success"
                    type="number"
                    onChangeHandler={handleTrackLTELengthChange}
                />
                <div className="col-span-1 flex items-center justify-center">
                    <button onClick={getData} className="bg-[#4318ff] text-white rounded-lg hover:bg-[#3290ff] hover:shadow-md h-[50%] w-[60%]">Get Data</button>
                </div>
                </div>
            </div>
        </div>

        <div className="mt-5 grid h-full grid-cols-1 gap-5 mb-5 bg-white p-2">
            <div className="m-2">
                <h6 className="text-xl font-bold text-navy-700 dark:text-white">Horses Filters</h6>
            </div>

            <div className="m-2">
                <div className="grid grid-cols-2 gap-2">
                    <div className="grid grid-cols-7">
                        <label className="text-center font-bold flex items-center justify-center mr-5">PP:</label>
                        <InputField
                            variant="auth"
                            extra="flex items-center mx-2 col-start-2 col-end-5"
                            label="Over:"
                            placeholder="ex: 1"
                            id="ppGteLength"
                            state="success"
                            type="number"
                            onChangeHandler={() => {return}}
                        />

                        <InputField
                            variant="auth"
                            extra="flex items-center mx-2 col-end-8 col-span-3"
                            label="Under:"
                            placeholder="ex: 5"
                            id="ppLteLength"
                            state="success"
                            type="number"
                            onChangeHandler={() => {return}}
                        />
                    </div>
                    <div className="grid grid-cols-7">
                        <label className="text-center font-bold flex items-center justify-center mr-5">PR:</label>
                        <InputField
                            variant="auth"
                            extra="flex items-center mx-2 col-start-2 col-end-5"
                            label="Over:"
                            placeholder="ex: 1"
                            id="prGteLength"
                            state="success"
                            type="number"
                            onChangeHandler={() => {return}}
                        />

                        <InputField
                            variant="auth"
                            extra="flex items-center mx-2 col-end-8 col-span-3"
                            label="Under:"
                            placeholder="ex: 5"
                            id="prLteLength"
                            state="success"
                            type="number"
                            onChangeHandler={() => {return}}
                        />
                    </div>

                    <div className="grid grid-cols-7">
                        <label className="text-center font-bold flex items-center justify-center mr-5">RLW:</label>
                        <InputField
                            variant="auth"
                            extra="flex items-center mx-2 col-start-2 col-end-5"
                            label="Over:"
                            placeholder="ex: 1"
                            id="rlwGteLength"
                            state="success"
                            type="number"
                            onChangeHandler={() => {return}}
                        />

                        <InputField
                            variant="auth"
                            extra="flex items-center mx-2 col-end-8 col-span-3"
                            label="Under:"
                            placeholder="ex: 5"
                            id="rlwLteLength"
                            state="success"
                            type="number"
                            onChangeHandler={() => {return}}
                        />
                    </div>

                    <div className="grid grid-cols-7">
                        <label className="text-center font-bold flex items-center justify-center mr-5">WGT:</label>
                        <InputField
                            variant="auth"
                            extra="flex items-center mx-2 col-start-2 col-end-5"
                            label="Over:"
                            placeholder="ex: 1"
                            id="wgtGteLength"
                            state="success"
                            type="number"
                            onChangeHandler={() => {return}}
                        />

                        <InputField
                            variant="auth"
                            extra="flex items-center mx-2 col-end-8 col-span-3"
                            label="Under:"
                            placeholder="ex: 5"
                            id="wgtLteLength"
                            state="success"
                            type="number"
                            onChangeHandler={() => {return}}
                        />
                    </div>

                    <div className="grid grid-cols-7">
                        <label className="text-center font-bold flex items-center justify-center mr-5">AFS:</label>
                        <InputField
                            variant="auth"
                            extra="flex items-center mx-2 col-start-2 col-end-5"
                            label="Over:"
                            placeholder="ex: 1"
                            id="afsGteLength"
                            state="success"
                            type="number"
                            onChangeHandler={() => {return}}
                        />

                        <InputField
                            variant="auth"
                            extra="flex items-center mx-2 col-end-8 col-span-3"
                            label="Under:"
                            placeholder="ex: 5"
                            id="afsLteLength"
                            state="success"
                            type="number"
                            onChangeHandler={() => {return}}
                        />
                    </div>

                    <div className="grid grid-cols-7">
                        <label className="text-center font-bold flex items-center justify-center mr-5">LS Rat:</label>
                        <InputField
                            variant="auth"
                            extra="flex items-center mx-2 col-start-2 col-end-5"
                            label="Over:"
                            placeholder="ex: 1"
                            id="lsratGteLength"
                            state="success"
                            type="number"
                            onChangeHandler={() => {return}}
                        />

                        <InputField
                            variant="auth"
                            extra="flex items-center mx-2 col-end-8 col-span-3"
                            label="Under:"
                            placeholder="ex: 5"
                            id="lsratLteLength"
                            state="success"
                            type="number"
                            onChangeHandler={() => {return}}
                        />
                    </div>

                    <div className="grid grid-cols-7">
                        <label className="text-center font-bold flex items-center justify-center mr-5">RAT:</label>
                        <InputField
                            variant="auth"
                            extra="flex items-center mx-2 col-start-2 col-end-5"
                            label="Over:"
                            placeholder="ex: 1"
                            id="ratGteLength"
                            state="success"
                            type="number"
                            onChangeHandler={() => {return}}
                        />

                        <InputField
                            variant="auth"
                            extra="flex items-center mx-2 col-end-8 col-span-3"
                            label="Under:"
                            placeholder="ex: 5"
                            id="ratLteLength"
                            state="success"
                            type="number"
                            onChangeHandler={() => {return}}
                        />
                    </div>

                    <div className="grid grid-cols-7">
                        <label className="text-center font-bold flex items-center justify-center mr-5">Back Price:</label>
                        <InputField
                            variant="auth"
                            extra="flex items-center mx-2 col-start-2 col-end-5"
                            label="Over:"
                            placeholder="ex: 1"
                            id="backpriceGteLength"
                            state="success"
                            type="number"
                            onChangeHandler={() => {return}}
                        />

                        <InputField
                            variant="auth"
                            extra="flex items-center mx-2 col-end-8 col-span-3"
                            label="Under:"
                            placeholder="ex: 5"
                            id="backpriceLteLength"
                            state="success"
                            type="number"
                            onChangeHandler={() => {return}}
                        />
                    </div>

                    <div className="grid grid-cols-7">
                        <label className="text-center font-bold flex items-center justify-center mr-5">Form L5:</label>
                        <InputField
                            variant="auth"
                            extra="flex items-center mx-2 col-start-2 col-end-5"
                            label="Over:"
                            placeholder="ex: 1"
                            id="formGteLength"
                            state="success"
                            type="number"
                            onChangeHandler={() => {return}}
                        />

                        <InputField
                            variant="auth"
                            extra="flex items-center mx-2 col-end-8 col-span-3"
                            label="Under:"
                            placeholder="ex: 5"
                            id="formLteLength"
                            state="success"
                            type="number"
                            onChangeHandler={() => {return}}
                        />
                    </div>

                    <div className="grid grid-cols-7">
                        <label className="text-center font-bold flex items-center justify-center mr-5">P:</label>
                        {/* <select className="w-full col-end-8 col-span-6 border border-[#22c55e] rounded-md text-center p-2" id="pSelect">
                            <option value="">All</option>
                            <option value="L">L</option>
                            <option value="P">P</option>
                            <option value="OP">OP</option>
                            <option value="M">M</option>
                        </select> */}
                        <Select 
                            value={pSelect} 
                            onChange={setPSelect}
                            isMulti
                            options={statePOption}
                            className="w-full col-end-8 col-span-6 p-2" 
                        />
                    </div>
                </div>
            </div>
        </div>

        <Card extra={"w-full pb-10 p-4 " + (loading?"h-screen":"h-full")}>
            <header className="relative flex items-center justify-between">
                <div className="text-xl font-bold text-navy-700 dark:text-white">
                    LiveRace table ({date.getFullYear()+"-"+pad(date.getMonth()+1)+ "-" + pad(date.getDate())})
                </div>
                {/* <div className="flex items-center">
                    <button onClick={getRawData} className="bg-[#4318ff] text-white rounded-lg hover:bg-[#3290ff] hover:shadow-md w-full p-2 text-xs">Add Extra Data</button>
                </div> */}
            </header>
            <div className="mt-8 overflow-x-auto">

                <table className="w-full">
                        <thead >

                            <tr className="!border-px !border-gray-400">
                                {raceData.length > 0 && <th
                                    className="cursor-pointer border-b-[1px] border-gray-200 pt-4 pb-2 pr-4 text-center"
                                >No
                                </th>}
                                {
                                    raceData.length > 0 && (
                                        Object.values(raceMapper).map(el => (
                                        <th 
                                            key={el}
                                            className="cursor-pointer border-b-[1px] border-gray-200 pt-4 pb-2 pr-4 text-center"
                                        >
                                            {el}
                                        </th>)
                                    ))
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {raceData?.map((row, i) => {
                                return(
                                <tr 
                                    key={i}
                                    onClick={(e)=>getRaceData(row.id, false)}
                                    className={"border-b hover:bg-gray-300 cursor-pointer "+(selectedRace && selectedRace.id === row.id ?"bg-gray-500":(row.marketStatus === "OPEN"? (row.inPlay? "bg-yellow-200":"OPEN"):(row.marketStatus === "SUSPENDED"?"bg-orange-010":"bg-orange-200")))}
                                >
                                    <td>{i+1}</td>
                                    {Object.values(row).map((cell,cellId) => {
                                        return(
                                            !Array.isArray(cell) && row.id !== cell && 
                                        <td
                                            key={cellId}
                                            className={"border-white/0 py-3 pr-4 text-center "+(cellId === 13?'bg-orange-300':'')}
                                        >
                                            {cellId === 10? (row.horses.every(obj => obj.placing == 'REMOVED')? 'RACING SUSPENDED': (row.marketStatus === "OPEN"? (row.inPlay? "INPLAY":"OPEN"):row.marketStatus)):(cell === row.start_at && row.marketStatus !=="CLOSED"? getDate(cell.toString() || ""): cell)}
                                        </td>
                                        )
                                    })}
                                </tr>
                                )
                            })}
                        </tbody>
                    </table>
            </div>
            {
                loading && !showModal && <div className="fixed h-screen w-screen top-0 left-0 mt-0  bg-[rgb(0,0,0,0.8)] flex items-center justify-center text-white text-xl">
                loading...
            </div>}

            </Card>
            {showModal && <>
                <div className="z-10 w-[calc(100%-100px)] -translate-x-1/2 -translate-y-1/2 h-[calc(100%-100px)] p-[30px] fixed top-1/2 left-1/2 bg-white overflow-y-auto">
                    <header className="relative flex items-center justify-between">
                        <div className="text-xl font-bold text-navy-700 dark:text-white">
                            {selectedRace.racecourse} &ensp;
                            {selectedRace.no} &ensp;
                            {selectedRace.name} &ensp;
                            {selectedRace.length}m &ensp;
                            {selectedRace.stake}&ensp;
                            {/* {selectedRace.start_at} &ensp; */}
                            {(selectedRace.marketStatus === "OPEN"? (selectedRace.inPlay? "INPLAY":"OPEN"):selectedRace.marketStatus)}&ensp;
                            {/* {selectedRace.marketStatus}&ensp; */}
                            {selectedRace.marketStatus !=="CLOSED" ?getDate(selectedRace.start_at): selectedRace.start_at}&ensp;
                            {loading && "..."}
                        </div>
                        <div 
                            className="cursor-pointer"
                            onClick={e => closeModal()}
                        >
                            Close
                        </div>
                    </header>

                    <div className="mt-8 overflow-x-auto">

                        <table className="w-full">
                                <thead >

                                    <tr className="!border-px !border-gray-400">
                                        {
                                            raceData.length > 0 && (
                                                Object.values(selectionMapper).map(el => (
                                                <th 
                                                    key={el}
                                                    className="cursor-pointer border-b-[1px] border-gray-200 pt-4 pb-2 pr-4 text-center"
                                                >
                                                    {el}
                                                </th>)
                                            ))
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedRace.horses?.map((row, i) => {
                                        return(
                                        <tr 
                                            key={i}
                                            // onClick={(e)=>clickRace(row.id)}
                                            className="border-b"
                                        >
                                            {
                                                Object.keys(selectionMapper).map((el, eId) => (
                                                    <td
                                                        key={eId}
                                                        className = {getCellClass(row,el)}
                                                    >
                                                        {row[el]}
                                                    </td>
                                                ))
                                            }
                                            {/* {Object.values(row).map((cell,cellId) => {
                                                return(
                                                    // !Array.isArray(cell) && row.id !== cell && 
                                                <td
                                                    key={cellId}
                                                    className="border-white/0 py-3  pr-4"
                                                >
                                                    
                                                    {cellId === 6 ? cell : (Array.isArray(cell)? (cell.length>0?cell[0].price.toFixed(2):0): ( Number.isNaN(Number(cell)) ? (cell === "REMOVED"? "SCRATCHED":cell): (cellId > 1 ?Number(cell).toFixed(2): cell)))}
                                                    
                                                </td>
                                                )
                                            })} */}
                                        </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                    </div>      
                </div>
                <div onClick={e => closeModal()} className="bg-[rgba(0,0,0,0.5)] h-screen fixed top-0 left-0 w-full z-[9]"></div>
                </>
            }
        </>
    )
}

export default LiveRace