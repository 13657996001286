import React from "react";
import CardMenu from "components/card/CardMenu";
import Card from "components/card";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";

export type RowObj = {
  racecourse:string;
  selection: number;
  avg_odds: number;
  avg_win_odds: number;
  avg_rated: number;
  wins: number;
  total: number;
  win_rate: number;
  win_odds: number;
  ev_div:number;
  ev: number;
};

function ColumnsTable(props: { tableData: any }) {
  const { tableData } = props;
  const [sorting, setSorting] = React.useState<SortingState>([]);
  let defaultData = tableData;
  const columns = [
    columnHelper.accessor("racecourse", {
      id: "racecourse",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">RaceCourse</p>
      ),
      cell: (info: any) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("selection", {
      id: "selection",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">SELECTION</p>
      ),
      cell: (info: any) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue() + 1}
        </p>
      ),
    }),
    columnHelper.accessor("avg_odds", {
      id: "avg_odds",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          AVG. Odds
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue().toFixed(2)}
        </p>
      ),
    }),
    columnHelper.accessor("avg_win_odds", {
      id: "avg_win_odds",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          AVG. WIN Odds
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue().toFixed(2)}
        </p>
      ),
    }),
    columnHelper.accessor("avg_rated", {
      id: "avg_rated",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          AVG. Rated
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue().toFixed(4)}
        </p>
      ),
    }),
    columnHelper.accessor("wins", {
      id: "wins",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">WINS</p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("total", {
      id: "total",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">TOTALS</p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("win_rate", {
      id: "win_rate",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">WIN RATE</p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {(info.getValue()*100).toFixed(4) + ' %'}
        </p>
      ),
    }),
    columnHelper.accessor("win_odds", {
      id: "win_odds",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">WIN ODDS</p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue().toFixed(4)}
        </p>
      ),
    }),
    columnHelper.accessor("ev_div", {
      id: "ev_div",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">EV Div</p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue().toFixed(4)}
        </p>
      ),
    }),
    columnHelper.accessor("ev", {
      id: "ev",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">EV%</p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue() < 0? ('[' + info.getValue().toFixed(4) + ']') : info.getValue().toFixed(4)}
        </p>
      ),
    }),
  ]; // eslint-disable-next-line
  const [data, setData] = React.useState(() => [...defaultData]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });
  return (
    <Card extra={"w-full pb-10 p-4 h-full"}>
      <header className="relative flex items-center justify-between">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          RaceTrade table
        </div>
        <CardMenu />
      </header>

      <div className="mt-8 overflow-x-auto">
        <table className="w-full">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="!border-px !border-gray-400">
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      onClick={header.column.getToggleSortingHandler()}
                      className="cursor-pointer border-b-[1px] border-gray-200 pt-4 pb-2 pr-4 text-start"
                    >
                      <div className="items-center justify-between text-xs text-gray-200">
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: "",
                          desc: "",
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table
              .getRowModel()
              .rows
              // .rows.slice(0, 5)
              .map((row) => {
                return (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td
                          key={cell.id}
                          className=" border-white/0 py-3  pr-4"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </Card>
  );
}

export default ColumnsTable;
const columnHelper = createColumnHelper<RowObj>();
