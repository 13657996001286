import { AiOutlineConsoleSql } from "react-icons/ai"

interface fetchOptionType {
    headers: any,
    method: string,
    credentials: RequestCredentials,
    body?: any
}

// const API_BASEURL = 'http://localhost:8000/api/v1'
const API_BASEURL = 'http://149.28.169.100/api/v1'
export const fetchAPI = (url: string, data: any, method = 'POST') => {
    const fetchURL =API_BASEURL + url
    const fetchOption : fetchOptionType = {
            headers: {
                'Content-Type': 'application/json',
            },
            method: method,
            credentials: "include",    
    }

    if (method !== "GET") {
        fetchOption['body'] = JSON.stringify(data)
    }
    return fetch(
            fetchURL,
            fetchOption
        ).then(async(res) => {
            if(!res.ok){
                return res.json().then(err => {
                    throw err
                })
            }

            if (res.status === 500){
                const err = {message:'Something went wrong with Server'}
                throw err;
            }

            return res.json()
        })
}

export const downloadFetchAPI = (url: string, data: any) => {
    const fetchURL = API_BASEURL + url

    const fetchOption : fetchOptionType = {
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'POST',
        credentials: "include",
        body: JSON.stringify(data)
}

    fetch(fetchURL, fetchOption)
    .then( res => res.blob() )
    .then( blob => {
        var now = new Date();
        var dd = String(now.getDate()).padStart(2, '0');
        var mm = String(now.getMonth() + 1).padStart(2, '0');
        var yyyy = now.getFullYear();

        var date_str = data.date ?? yyyy + '-' + mm + '-' + dd;
        
        var bUrl = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = bUrl;
        a.download = "filename" + date_str + ".xlsx";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();    
        a.remove();  //afterwards we remove the element again     
    });
}