import { useEffect, useState, ChangeEvent } from "react"
// import RaceTable, { RaceObj } from "./components/RaceTable"\
import { prevRaceDetailAPI, prevRacesAPI, getRawDataAPI } from "api"
import Card from "components/card"
import Select, {MultiValue,StylesConfig } from 'react-select';
import 'react-select-search/style.css'
import InputField from "components/fields/InputField";
import optionList from 'data/option_list.json'
import chroma from 'chroma-js';
import moment from 'moment-timezone';
import { stringify } from "querystring";
import Calendar from "react-calendar";
// import HorseTable from "./components/HorseTable"
export type RowObj = {
    id:string,
    state: string;
    racecourse: string;
    no: number;
    start_at: string;
    name: string;
    status: string;
    type: string;
    weather:string;
    length: number;
    horses?: Array<SelectionObj>;
    marketStatus?: string;
    inPlay: boolean;
    stake:string;
    numberOfRunners: number;
  };
export type Odd = {
    price: number,
    size: number
}
export type SelectionObj = {
    selection:number,
    tab:string,
    horse:string,
    jockey:string,
    rat: string,
    bp_adj: string,
    wgt:string,
    hi_wt:string,
    p:string,
    afs:string,
    pp:string,
    uo:string,
    // rated:string,
    ct: number,
    rst: number,
    back_odds:Array<Odd>,
    lay_odds: Array<Odd>,
    placing: string,
    placedResult: number,
    rf_prediction: string,
    rf_prediction_1min: string,
    rf_prediction_2min: string,
    rf_prediction_3min: string
}
  
const LiveRace = () => {
    const [raceData, setRaceData] = useState<Array<RowObj>>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [showModal, setShowModal] = useState<boolean>(false)
    const [selectedRace, setSelectedRace] = useState<RowObj>(null)
    const [timerId, setTimerId] = useState<NodeJS.Timeout>(null)
    const [curerntTime, setCurrentTime] = useState<Date>(null)
    const [horseRunning, setHorseRunning] = useState<MultiValue<OptionType>>([])
    const [trackGTELength, setTrackGTELength] = useState<number>(0)
    const [trackLTELength, setTrackLTELength] = useState<number>(0)
    const [ppOver, setPPOverChange] = useState<number>(0)
    const [ppUnder, setPPUnderChange] = useState<number>(0)
    const [ctOver, setCTOverChange] = useState<number>(0)
    const [ctUnder, setCTUnderChange] = useState<number>(0)
    const [wgtOver, setWgtOverChange] = useState<number>(0)
    const [wgtUnder, setWgtUnderChange] = useState<number>(0)
    const [level, setLevelChange] = useState<number>(0)
    const [margin, setMarginChange] = useState<number>(0)
    const [raceCourseTypeGroup, setRaceCourseTypeGroup] = useState<string>("group")
    const [sortByTime, setSortByTime] = useState<boolean>(true)
    const [fromDate, onFromDateChange] = useState<Date>(new Date())
    const [toDate, onToDateChange] = useState<Date>(new Date())
    const [trackType, setTrackType] = useState<MultiValue<OptionType>>([])
    const [raceType, setRaceType] = useState<MultiValue<OptionType>>([])
    const [stateAU, setStateAU] = useState<MultiValue<OptionType>>([])
    const date = new Date(new Date().toLocaleString(undefined, {
        timeZone: "Australia/Sydney"
    }))
        
    interface OptionType {
        value: string;
        label: string;
    }
    interface ColourOption {
        readonly value: string;
        readonly label: string;
        readonly color: string;
        readonly isFixed?: boolean;
        readonly isDisabled?: boolean;
    }
    
    const colourOptions: OptionType[] = [
        { value: 'MDN', label: 'MDN'},
        { value: 'CL1', label: 'CL1'},
        { value: 'CL2', label: 'CL2'},
        { value: 'CL3', label: 'CL3'},
        { value: 'CL4', label: 'CL4'},
        { value: 'CL5', label: 'CL5'},
        { value: 'CL6', label: 'CL6'},
        { value: '2U', label: '2U'},
        { value: '3O', label: '3O'},
        { value: 'FM', label: 'FM'},
        { value: 'BM34-70', label: 'BM34-70'},
        { value: 'BM71-90', label: 'BM71-90'},
        { value: 'HDL & STLE', label: 'HDL & STLE'},
        { value: 'GROUPS', label: 'GROUPS'},
    ];
    
    const selectionOptions : OptionType[]  = optionList.horseRunningList

    const stateOptions : OptionType[]  = optionList.stateList
    const raceCourseOptions : OptionType[]  = optionList.raceCourseList
    const raceTypeOptions : OptionType[]  = optionList.raceTypeList
    const trackTypeOptions : OptionType[]  = optionList.trackTypeList
    const horseRunningOptions : OptionType[]  = optionList.horseRunningList

    const changeSort = (e: ChangeEvent<HTMLInputElement>) => {
        setSortByTime(e.target.checked)
    }

    const getSorted = () => {
        if(selectedRace && selectedRace.horses){

            const afsList = selectedRace.horses.map(horse => {
                if ( horse.afs){
                    return Number(horse.afs)
                } else {
                    return 0
                }
            })
            const uniq = [...new Set(afsList)];
            //Sort the array from lowest to highest
            //Thanks to Charlietfl, I forgot to sort it numerically
            uniq.sort(function(a, b) {
            return b - a;
            });
        
            return uniq
        } else {
            return []
        }

    }

    const getColor  = (value: string) => {
        let color: string = "#333333"
        // 1. filter for "MDN"
        if (value.indexOf("MDN") >=0 ){
        color = "#FF0000"
        return color
        }

        if( value.indexOf("CL1") >=0) {
        color="#0000FF"
        return color
        }

        if( value.indexOf("CL2") >=0) {
        color="#00FF00"
        return color
        }

        if( value.indexOf("CL3") >=0) {
        color="#4B0082"
        return color
        }

        if( value.indexOf("CL4") >=0) {
        color="#FFA500"
        return color
        }

        if( value.indexOf("CL5") >=0) {
        color="#800080"
        return color
        }

        if( value.indexOf("CL5") >=0) {
        color="#FFC0CB"
        return color
        }

        if( value.indexOf("CL7") >=0) {
        color="#A52A2A"
        return color
        }

        if( value.indexOf("2U") >=0) {
        color="#00FFFF"
        return color
        }

        if( value.indexOf("3O") >=0) {
        color="#FF00FF"
        return color
        }
        
        if( value.indexOf("FM") >=0) {
        color="#008080"
        return color
        }

        if( value.indexOf("BM") >=0) {
        color="#40E0D0"
        return color
        }

        if( value.indexOf("OPEN") >=0) {
        color="#800000"
        return color
        }

        if( value.indexOf("HDL") >=0) {
        color="#000080"
        return color
        }

        if( value.indexOf("G") >=0) {
        color="#FFD700"
        return color
        }
        return color
    } 
    const colourStyles: StylesConfig<OptionType, true> = {
        control: (styles) => ({ ...styles, backgroundColor: 'white' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        
        const dataColor = getColor(data.value)

        // 1. Filter for "MDN"
        

        const color = chroma(dataColor);
        return {
            ...styles,
            backgroundColor: isDisabled
            ? undefined
            : isSelected
            ? dataColor
            : isFocused
            ? color.alpha(0.1).css()
            : undefined,
            color: isDisabled
            ? '#ccc'
            : isSelected
            ? chroma.contrast(color, 'white') > 2
                ? 'white'
                : 'black'
            : dataColor,
            cursor: isDisabled ? 'not-allowed' : 'default',
    
            ':active': {
            ...styles[':active'],
            backgroundColor: !isDisabled
                ? isSelected
                ? dataColor
                : color.alpha(0.3).css()
                : undefined,
            },
        };
        },
        multiValue: (styles, { data }) => {
        const dataColor = getColor(data.value)
        const color = chroma(dataColor);
        return {
            ...styles,
            backgroundColor: color.alpha(0.1).css(),
        };
        },
        multiValueLabel: (styles, { data }) => ({
        ...styles,
        color:  getColor(data.value),
        }),
        multiValueRemove: (styles, { data }) => ({
        
        ...styles,
        color:  getColor(data.value),
        ':hover': {
            backgroundColor:  getColor(data.value),
            color: 'white',
        },
        }),
    };

    const handleTrackGTELengthChange = (e:ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setTrackGTELength(parseInt(e.currentTarget.value || "0"))
    }

    const handleTrackLTELengthChange = (e:ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setTrackLTELength(parseInt(e.currentTarget.value || "0"))
    }

    const handleGroupChange = (e: ChangeEvent<HTMLInputElement>) => {
        setRaceCourseTypeGroup(e.currentTarget.value)
        setRaceType([])
    }

    const handlePPOverChange = (e:ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setPPOverChange(parseInt(e.currentTarget.value || "0"))
    }
    const handlePPUnderChange = (e:ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setPPUnderChange(parseInt(e.currentTarget.value || "0"))
    }
    const handleCTOverChange = (e:ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setCTOverChange(parseInt(e.currentTarget.value || "0"))
    }
    const handleCTUnderChange = (e:ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setCTUnderChange(parseInt(e.currentTarget.value || "0"))
    }
    const handleWgtOverChange = (e:ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setWgtOverChange(parseInt(e.currentTarget.value || "0"))
    }
    const handleWgtUnderChange = (e:ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setWgtUnderChange(parseInt(e.currentTarget.value || "0"))
    }
    const handleMarginChange = (e:ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setMarginChange(parseInt(e.currentTarget.value || "0"))
    }
    const handleLevelChange = (e:ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setMarginChange(parseInt(e.currentTarget.value || "0"))
    }
    const getData = () => {
        const sort = (document.getElementById("sort-by-time") as HTMLInputElement)?.checked
        const data = {
            state:stateAU?.map(el=>el.value),
            raceType:raceType?.map(el=>el.value),
            raceTypeGroup:raceCourseTypeGroup === "group",
            trackType:trackType?.map(el=>el.value),
            horseRunning: horseRunning?.map(el=>Number(el.value)),
            trackGTELength:trackGTELength,
            trackLTELength: trackLTELength,
            ppOver: ppOver,
            ppUnder: ppUnder,
            ctUnder: ctUnder,
            ctOver: ctOver,
            wgtOver: wgtOver,
            wgtUnder: wgtUnder,
            level: level,
            margin: margin,
            sortByTime:sort,
            date: fromDate.getFullYear()+"-"+pad(fromDate.getMonth()+1)+ "-" + pad(fromDate.getDate()),
          }
        prevRacesAPI(data).then(res => {
            const data = res.result.map((el : any) => {
                let obj : RowObj = {
                    state: el.state,
                    racecourse:el.racecourse,
                    no:el.no,
                    start_at:el.start_at,
                    name:el.name,
                    status:el.status,
                    type:el.type,
                    weather:el.weather+"-"+el.stat,
                    length:el.length,
                    stake:el.stake,
                    marketStatus:el.marketStatus,
                    inPlay:el.inplay,
                    id:el._id,
                    numberOfRunners:el.horses.length,
                }
                
                return obj
            })
            setRaceData(data)
        })
    }

    const getRawData = () => {
        const date = fromDate.getFullYear()+"-"+pad(fromDate.getMonth()+1)+ "-" + pad(fromDate.getDate())
        getRawDataAPI({date})
    }

    const raceMapper = {
        state:"State",
        racecourse:"Racecourse",
        no:"Race No",
        start_at:"Start Time",
        name:"Race Name",
        status:"Turf",
        type:"Track Type",
        weather:"Weather",
        length:"Length",
        stake:"Total Stake",
        marketStatus:"Market Status",
        id:"",
        numberOfRunners: "Total Runners",
    }

    const selectionMapper = {
        selection: "Selection",
        tab:"TAB",
        horse:"Horse Name",
        jockey:"Jockey Name",
        rat:"Rat",
        bp_adj:"Barrier",
        wgt:"Weight",
        hi_wt:'WWW',
        p: 'RP',
        afs: 'AFS',
        pp:"PP",
        uo:"U/O",
        // rated:"Rated",
        ct:"CT",
        rst:"RST",
        back_odds:"Back Odds",
        lay_odds: "Lay Odds",
        placing: "Placing",
        placedResult: "Placed Result",
        rf_prediction: "RFP",
        rf_prediction_1min: "RFP(1min)",
        rf_prediction_2min: "RFP(2min)",
        rf_prediction_3min: "RFP(3min)"
    }
  
    const getRaceData = (id:string, repeat: boolean) => {
        const data = {
            id: id
        }

        const row = raceData.find(el => el.id ===id)
        if(row){
            setLoading(true)
            prevRaceDetailAPI(data).then(res => {
                console.log(res)
                if(res){
                    let horses : SelectionObj[] = []

                    let totalRFPrediction = 0;
                    let totalRFPrediction_1min = 0;
                    let totalRFPrediction_2min = 0;
                    let totalRFPrediction_3min = 0;

                    for (let i = 0; i < res.result.horses.length; i++) {
                        totalRFPrediction += res.result.horses[i].rf_prediction;
                        totalRFPrediction_1min += res.result.horses[i].rf_prediction_1min;
                        totalRFPrediction_2min += res.result.horses[i].rf_prediction_2min;
                        totalRFPrediction_3min += res.result.horses[i].rf_prediction_3min;
                    }

                    res.result.horses.filter((el:any, idx: number)=> {
                        if(el.pp !== 999){
                            let uo = ''
                            if (el.back_odds.length > 0) {
                                if (el.back_odds[0].price <= el.pp) {
                                    uo = 'U'
                                } else {
                                    uo = 'O'
                                }
                            }

                            let obj : SelectionObj = {
                                selection:idx+1,
                                tab:el.tab,
                                horse:el.horse,
                                jockey:el.jockey,
                                rat: el.rat,
                                bp_adj:el.bp_adj,
                                wgt:el.wgt,
                                hi_wt:el.hi_wt,
                                p:el.p,
                                afs:el.afs,
                                pp:el.pp,
                                uo:uo,
                                // rated:el.rated,
                                ct: el.ct,
                                rst: 0,
                                back_odds:el.back_odds,
                                lay_odds: el.lay_odds,
                                placing: el.placing,
                                placedResult: el.placedResult,
                                rf_prediction: parseFloat((el.rf_prediction / totalRFPrediction*100).toFixed(1)) + '%',
                                rf_prediction_1min: parseFloat((el.rf_prediction_1min / totalRFPrediction_1min*100).toFixed(1)) + '%',
                                rf_prediction_2min: parseFloat((el.rf_prediction_2min / totalRFPrediction_2min*100).toFixed(1)) + '%',
                                rf_prediction_3min: parseFloat((el.rf_prediction_3min / totalRFPrediction_3min*100).toFixed(1)) + '%',
                            }
                            horses.push(obj)
                        }
                    })

                    let obj : RowObj = {
                        state: res.result.state,
                        racecourse:res.result.racecourse,
                        no:res.result.no,
                        start_at:res.result.start_at,
                        name:res.result.name,
                        status:res.result.status,
                        type:res.result.type,
                        weather:res.result.weather,
                        horses: horses,
                        length:res.result.length,
                        stake: res.result.stake,
                        marketStatus:res.result.marketStatus,
                        inPlay:res.result.inplay,
                        numberOfRunners:horses.length,
                        id:res.result._id
                    }

                    setSelectedRace(obj)
                    if(!repeat){
                        setSelectedRace(obj)
                        setShowModal(true)
                    } else {
                        if(selectedRace.id === obj.id){
                            setSelectedRace(obj)
                        }
                    }
                    setLoading(false)

                }
            })
        }
    }
    useEffect(()=> {

        const clock = setInterval(()=>{
            let d = new Date();
            setCurrentTime(d)
        }, 1000)
    }, [])


    const getCellData = (row : SelectionObj, el : string) => {
        let result;
        const element = row[el as keyof typeof row]

        switch (el){
            case 'back_odds':
            case 'lay_odds':
                const temp = element as Array<Odd>
                if(temp && temp.length > 0){ 
                    result = temp[0].price.toFixed(2)
                } else {
                    result = ""
                }
                break;
            // case 'rated':
            //     result = element as number
            //     result = result.toFixed(2)
            //     break;
            case 'pp':
            case 'wgt':
                result = element as string
                result = parseFloat(result).toFixed(2)
                break;
            case 'hi_wt':
                result = element as string
                if(!isNaN(Number(result))){
                    try {
                        result = (Number(row.wgt) - Number(result)).toFixed(1)
                    } catch (e){
                        result = "-"
                    }
                } else {
                    result = "-"
                }
                break;
            case 'rst':
                result = element as string
                if (isNaN(Number(row.hi_wt))) {
                    result = Number(row.rat) + Number(row.wgt) + Number(row.ct)
                } else {
                    result = Number(row.rat) + 2* Number(row.wgt) - Number(row.hi_wt)  + Number(row.ct)
                }
                break;
            case 'placing':
                result = element as string
                if(result === 'REMOVED'){
                    result ='SCRATCHED'
                }
                break;
            case 'placedResult':
                result = element as string
                if(result === '' || result === '0'){
                    result ='-'
                }
                break;
            case 'afs':
                result = element as string
                let sortedList = getSorted()
                if(result){
                    const idx = sortedList.indexOf(Number(result))

                    result = (idx >=0 ?idx + 1 : 1).toString()
                } else {
                    result = (sortedList.length + 1).toString()
                }
                break;
            default:
                result = element as string
                break;
        }

        return result
    }

    const getCellClass = (row : SelectionObj, el : string) => {
        let result = "border-white/0 py-3  pr-4 text-center "
        const element = row[el as keyof typeof row]

        let temp;
        switch (el) {
            case 'rat':
                temp = element as number
                if(temp >= 5){
                    result += "bg-yellow-200 "
                }
                break;
            case 'bp_adj':
                temp = element as string
                if(Number(temp) >= 10){
                    result += "bg-yellow-200 "
                }
                break;
            case 'wgt':
                temp = element as string
                if(Number(temp) >= 59){
                    result += "bg-yellow-200 "
                }
                break;
            case 'hi_wt':
                temp = element as string
                if(!isNaN(Number(temp))){
                    try {
                        temp = (Number(row.wgt) - Number(temp))
                        if(temp >= 2){
                            result += "bg-yellow-200 "
                        }
                    } catch (e){
                        // result += "bg-yellow-200 "
                    }
                } else {
                    // result += "bg-yellow-200 "
                }
                break;
            case 'afs':
                temp = element as string
                let sortedList = getSorted()
                if(temp){
                    const idx = sortedList.indexOf(Number(temp))

                    temp = (idx >=0 ?idx + 1 : 1)
                } else {
                    temp = (sortedList.length + 1)
                }
                if(temp >= 5){
                    result += "bg-yellow-200 "
                }
                break; 
            case 'p':
                temp = element as string
                if(temp==="OM" || temp === "BK"){
                    result += "bg-yellow-200 "
                }
                break;
            case 'placing':
                temp = element as string
                if(temp==="WINNER"){
                    result += "bg-green-200 "
                }
                break; 
        }
        return result
    }
    
    const closeModal = () => {
        clearInterval(timerId)
        setTimerId(null)
        setShowModal(false)

    }
    const pad = (num: number)=> {
        return (num < 10 ? '0' : '') + num;
    };

    const  msToTime = (duration:number) =>{
        let milliseconds = Math.floor((duration % 1000) / 100),
          seconds = Math.floor((duration / 1000) % 60),
          minutes = Math.floor((duration / (1000 * 60)) % 60),
          hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
      
        let hours_str = (hours < 10) ? "0" + hours : hours.toString();
        let minutes_str = (minutes < 10) ? "0" + minutes : minutes.toString();
        let seconds_str = (seconds < 10) ? "0" + seconds : seconds.toString();
      
        return hours_str + ":" + minutes_str + ":" + seconds_str;
      }
    const getDate = (hours:string) => {
        try{
            
            const raceDate = new Date(`${date.getFullYear()}-${pad(date.getMonth()+1)}-${pad(date.getDate())}T${hours}:00+10:00`)
            const sydneyDate = moment.tz(`${date.getFullYear()}-${pad(date.getMonth()+1)}-${pad(date.getDate())}T${hours}`, "Australia/Sydney").toDate();
            // console.log(raceDate, sydneyDate)
            const delta = sydneyDate.getTime() - curerntTime.getTime()
            if (delta < 0){
                if (delta < -1200*1000) return hours
                else {
                    return "-"+msToTime(Math.abs(delta))
                }
            } else {
                if ( delta > 3600*1000) return hours
                else {
                    return msToTime(delta)
                }
            }
        } catch{
            return "00:00:00"
        }
    }

    useEffect(()=>{
        if(showModal){
            getRaceData(selectedRace.id, true)
        } else {
            closeModal()
        }
    }, [showModal])

    useEffect(()=>{
        if(fromDate){
            getData()
        }
    }, [fromDate])

    return (
        <>
        <div className="mt-5 grid h-full grid-cols-1 gap-5 mb-5 bg-white">
            <div>
                
                <div className="mt-2 grid grid-cols-5 gap-2">
                <label className="col-span-1 text-center flex items-center justify-center">State:</label>
                <Select 
                    value={stateAU} 
                    onChange={setStateAU}
                    isMulti
                    options={stateOptions} 
                    className="col-span-3" 
                    />
                </div>
                <div className="mt-2 grid grid-cols-5 gap-2">
                <label className="col-span-1 text-center flex items-center justify-center">Race Course Type:</label>
                <div className="col-span-3 gap-2 grid grid-cols-4">
                    <div className="col-span-1 flex items-center">

                    <label>group</label>
                    <input type="radio" checked={raceCourseTypeGroup === "group"} value="group" onChange={handleGroupChange} name="raceCourseTypeGroup" className="ml-2"/>
                    </div>
                    <div className="col-span-1 flex items-center">
                    <label>individual</label>
                    <input type="radio" checked={raceCourseTypeGroup ==="individual"} value="individual" onChange={handleGroupChange} name="raceCourseTypeGroup" className="ml-2"/>
                    </div>
                </div>
                </div>
                <div className="mt-2 grid grid-cols-5 gap-2">
                <label className="col-span-1 text-center flex items-center justify-center"></label>
                

                <Select 
                    value={raceType} 
                    onChange={setRaceType}
                    isMulti
                    styles={colourStyles}
                    options={raceCourseTypeGroup === "group" ?colourOptions: raceTypeOptions}
                    // options={ColourOption} 
                    className="col-span-3" 
                    />
                </div>
                <div className="mt-2 grid grid-cols-5 gap-2">
                <label className="col-span-1 text-center flex items-center justify-center">Track Type:</label>
                <Select 
                    value={trackType} 
                    onChange={setTrackType}
                    isMulti
                    options={trackTypeOptions} 
                    className="col-span-3" 
                    />
                </div>
                <div className="mt-2 grid grid-cols-5 gap-2">
                <label className="col-span-1 text-center flex items-center justify-center">Horse Running:</label>
                <Select 
                    value={horseRunning} 
                    onChange={setHorseRunning}
                    isMulti
                    options={horseRunningOptions} 
                    className="col-span-3" 
                    />
                </div>
                <div className="mt-2 grid grid-cols-5 gap-2">
                <label className="col-span-1 text-center flex items-center justify-center">Sort By Time:</label>
                <input type="checkbox" id="sort-by-time" onChange={changeSort} checked={sortByTime}></input>
                </div>

                <div className="mt-3 grid grid-cols-2 bg-white gap-5 p-5 rounded-lg">
                    <div className="col-span-1">
                        From Date:
                        <Calendar  onChange={onFromDateChange} value={fromDate}/>
                    </div>
                </div>
                <div className="mt-5 grid  grid-cols-7 gap-5 bg-white p-5 rounded-lg" >
                {/* FromSelection */}
                <InputField
                    variant="auth"
                    extra="mb-3"
                    label="Level Over:"
                    placeholder="ex: 1"
                    id="gteLength"
                    state="success"
                    type="number"
                    onChangeHandler={handleTrackGTELengthChange}
                />
                {/* ToSelection */}
                <InputField
                    variant="auth"
                    extra="mb-3"
                    label="Length Under:"
                    placeholder="ex: 5"
                    id="lteLength"
                    state="success"
                    type="number"
                    onChangeHandler={handleTrackLTELengthChange}
                />
                {/* FromSelection */}
               
                <div className="col-span-1 flex items-center justify-center">
                    <button onClick={getData} className="bg-[#4318ff] text-white rounded-lg hover:bg-[#3290ff] hover:shadow-md h-[50%] w-[60%]">Get Data</button>
                </div>
                </div>
            </div>
        </div>
            <Card extra={"w-full pb-10 p-4 " + (loading?"h-screen":"h-full")}>
                <header className="relative flex items-center justify-between">
                    <div className="text-xl font-bold text-navy-700 dark:text-white">
                        PrevRaces table ({fromDate.getFullYear()+"-"+pad(fromDate.getMonth()+1)+ "-" + pad(fromDate.getDate())})
                    </div>
                    {/* <div className="flex items-center">
                        <button onClick={getRawData} className="bg-[#4318ff] text-white rounded-lg hover:bg-[#3290ff] hover:shadow-md w-full p-2 text-xs">Add Extra Data</button>
                    </div> */}
                </header>
            <div className="mt-8 overflow-x-auto">

                <table className="w-full">
                        <thead >

                            <tr className="!border-px !border-gray-400">
                                {raceData.length > 0 && <th
                                    className="cursor-pointer border-b-[1px] border-gray-200 pt-4 pb-2 pr-4 text-start"
                                >No
                                </th>}
                                {
                                    raceData.length > 0 && (
                                        Object.values(raceMapper).map(el => (
                                        <th 
                                            key={el}
                                            className="cursor-pointer border-b-[1px] border-gray-200 pt-4 pb-2 pr-4 text-start"
                                        >
                                            {el}
                                        </th>)
                                    ))
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {raceData?.map((row, i) => {
                                return(
                                <tr 
                                    key={i}
                                    onClick={(e)=>getRaceData(row.id, false)}
                                    className={"border-b hover:bg-gray-300 cursor-pointer "+(selectedRace && selectedRace.id === row.id ?"bg-gray-500":(row.marketStatus === "OPEN"? (row.inPlay? "bg-yellow-200":"OPEN"):(row.marketStatus === "SUSPENDED"?"bg-orange-100":"bg-orange-200")))}
                                >
                                    <td>{i+1}</td>
                                    {Object.values(row).map((cell,cellId) => {
                                        return(
                                            !Array.isArray(cell) && row.id !== cell && 
                                        <td
                                            key={cellId}
                                            className={"border-white/0 py-3 pr-4 text-center "+(cellId === 13?'bg-orange-300':'')}
                                        >
                                            {cellId === 10? (row.marketStatus === "OPEN"? (row.inPlay? "INPLAY":"OPEN"):row.marketStatus):(cell === row.start_at && row.marketStatus !=="CLOSED"? getDate(cell.toString() || ""): cell)}
                                        </td>
                                        )
                                    })}
                                </tr>
                                )
                            })}
                        </tbody>
                    </table>
            </div>
            {
                loading && !showModal && <div className="fixed h-screen w-screen top-0 left-0 mt-0  bg-[rgb(0,0,0,0.8)] flex items-center justify-center text-white text-xl">
                loading...
            </div>}

            </Card>
            {showModal && <>
                <div className="z-10 w-[calc(100%-100px)] -translate-x-1/2 -translate-y-1/2 h-[calc(100%-100px)] p-[30px] fixed top-1/2 left-1/2 bg-white  overflow-y-auto">
                    <header className="relative flex items-center justify-between">
                        <div className="text-xl font-bold text-navy-700 dark:text-white">
                            {selectedRace.racecourse} &ensp;
                            {selectedRace.no} &ensp;
                            {selectedRace.name} &ensp;
                            {selectedRace.length}m &ensp;
                            {selectedRace.stake} &ensp;
                            {/* {selectedRace.start_at} &ensp; */}
                            {(selectedRace.marketStatus === "OPEN"? (selectedRace.inPlay? "INPLAY":"OPEN"):selectedRace.marketStatus)}&ensp;
                            {/* {selectedRace.marketStatus}&ensp; */}
                            {selectedRace.marketStatus !=="CLOSED" ?getDate(selectedRace.start_at): selectedRace.start_at}&ensp;
                            {loading && "..."}
                        </div>
                        <div 
                            className="cursor-pointer"
                            onClick={e => closeModal()}
                        >
                            Close
                        </div>
                    </header>
                    <div className="mt-8 overflow-x-auto">

                        <table className="w-full">
                                <thead >

                                    <tr className="!border-px !border-gray-400">
                                        {
                                            raceData.length > 0 && (
                                                Object.values(selectionMapper).map(el => (
                                                <th 
                                                    key={el}
                                                    className="cursor-pointer border-b-[1px] border-gray-200 pt-4 pb-2 pr-4 text-center"
                                                >
                                                    {el}
                                                </th>)
                                            ))
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedRace.horses?.map((row, i) => {
                                        return(
                                        <tr 
                                            key={i}
                                            // onClick={(e)=>clickRace(row.id)}
                                            className="border-b"
                                        >
                                            {
                                                Object.keys(selectionMapper).map((el, eId) => (
                                                    <td
                                                        key={eId}
                                                        className = {getCellClass(row,el)}
                                                    >
                                                        {getCellData(row,el)}
                                                    </td>
                                                ))
                                            }
                                            {/* {Object.values(row).map((cell,cellId) => {
                                                return(
                                                    // !Array.isArray(cell) && row.id !== cell && 
                                                <td
                                                    key={cellId}
                                                    className="border-white/0 py-3  pr-4"
                                                >
                                                    
                                                    {cellId === 6 ? cell : (Array.isArray(cell)? (cell.length>0?cell[0].price.toFixed(2):0): ( Number.isNaN(Number(cell)) ? (cell === "REMOVED"? "SCRATCHED":cell): (cellId > 1 ?Number(cell).toFixed(2): cell)))}
                                                    
                                                </td>
                                                )
                                            })} */}
                                        </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                    </div>      
                </div>
                <div onClick={e => closeModal()} className="bg-[rgba(0,0,0,0.5)] h-screen fixed top-0 left-0 w-full z-[9]"></div>
                </>
            }
        </>
    )
}

export default LiveRace