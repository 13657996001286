import InputField from "components/fields/InputField";
// import { FcGoogle } from "react-icons/fc";
// import Checkbox from "components/checkbox";
import { useNavigate } from "react-router-dom";
import { loginAPI } from 'api'
import {ChangeEvent, MouseEvent, useState} from 'react';

export default function SignIn() {
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [emailState, setEmailState] = useState<string>("");
  const [passwordState, setPasswordState] = useState<string>("");

    //----------------- Email Change Handler ----------------//
    const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      setEmail(e.currentTarget.value);
  }

  //----------------- Password Change Handler ----------------//
  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      setPassword(e.currentTarget.value);
      
  }

  const handleSignin = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    // const email = document.getElementById('email').innerText
    // const password = document.getElementById('password').innerText

    console.log(email,password)
    const data = {
      email: email,
      password:password
    }

    
    /** 
    * Sign in using email and password.
    * @method POST
    * @route auth/login
    * @param password
    * @param email
    * @success redirect to main panel
    * @failure show toast
    */
    loginAPI(data).then(res => {
      console.log(res)
      navigate('/admin/odds')
    }).catch(err => {
      console.log(err.msg)
      if(Array.isArray(err.msg)){
        const errEmailState = err.msg.findIndex((e : any) => e.path==="email") >= 0 ? 'error' : 'success'
        setEmailState(errEmailState)

        const errPassState = err.msg.findIndex((e : any) => e.path==="password") >= 0 ? 'error' : 'success'
        setPasswordState(errPassState)
      } else {
        setEmailState("success")
        setPasswordState("success")
      }

    })
  }
  return (
    <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Sign In
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Enter your email and password to sign in!
        </p>
        {/* <div className="mb-6 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-lightPrimary hover:cursor-pointer dark:bg-navy-800">
          <div className="rounded-full text-xl">
            <FcGoogle />
          </div>
          <h5 className="text-sm font-medium text-navy-700 dark:text-white">
            Sign In with Google
          </h5>
        </div>
        <div className="mb-6 flex items-center  gap-3">
          <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
          <p className="text-base text-gray-600 dark:text-white"> or </p>
          <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
        </div> */}
        {/* Email */}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Email*"
          placeholder="mail@simmmple.com"
          id="email"
          type="text"
          state={emailState}
          onChangeHandler={handleEmailChange}
        />

        {/* Password */}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Password*"
          placeholder="Min. 8 characters"
          id="password"
          type="password"
          state={passwordState}
          onChangeHandler={handlePasswordChange}
        />
        {/* Checkbox */}
        {/* <div className="mb-4 flex items-center justify-between px-2">
          <div className="flex items-center">
            <Checkbox />
            <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
              Keep me logged In
            </p>
          </div>
          <a
            className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
            href=" "
          >
            Forgot Password?
          </a>
        </div> */}
        <button onClick={handleSignin} className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
          Sign In
        </button>
        {/* <div className="mt-4">
          <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
            Not registered yet?
          </span>
          <a
            href=" "
            className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
          >
            Create an account
          </a>
        </div> */}
      </div>
    </div>
  );
}
