import React from "react";

// Admin Imports
import MainDashboard from "views/admin/default";
import OddsTable from "views/admin/tables";
import RaceCourse from "views/admin/racecourse";
import LiveRace from "views/admin/liverace";
import PrevRace from "views/admin/prevrace";

// Auth Imports
import SignIn from "views/auth/SignIn";

// Icon Imports
import {
  MdHome,
  MdOutlineShoppingCart,
  MdBarChart,
  MdPerson,
  MdHistory,
  MdLock,
  MdRunCircle,
  MdCompare
} from "react-icons/md";

const routes = [
  // {
  //   name: "Main Dashboard",
  //   layout: "/admin",
  //   path: "default",
  //   icon: <MdHome className="h-6 w-6" />,
  //   component: <MainDashboard />,
  // },
  // {
  //   name: "NFT Marketplace",
  //   layout: "/admin",
  //   path: "nft-marketplace",
  //   icon: <MdOutlineShoppingCart className="h-6 w-6" />,
  //   component: <NFTMarketplace />,
  //   secondary: true,
  // },
  {
    name: "Data Tables",
    layout: "/admin",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "odds",
    component: <OddsTable />,
  },
  {
    name: "Best Racecourse",
    layout: "/admin",
    icon: <MdCompare className="h-6 w-6" />,
    path: "racecourse",
    component: <RaceCourse />,
  },
  {
    name: "Live Races",
    layout: "/admin",
    path: "liverace",
    icon: <MdRunCircle className="h-6 w-6" />,
    component: <LiveRace />,
  },
  {
    name: "Prev Races",
    layout: "/admin",
    path: "prevrace",
    icon: <MdHistory className="h-6 w-6" />,
    component: <PrevRace />,
  },
  // {
  //   name: "Profile",
  //   layout: "/admin",
  //   path: "profile",
  //   icon: <MdPerson className="h-6 w-6" />,
  //   component: <Profile />,
  // },
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
  },

  // {
  //   name: "RTL Admin",
  //   layout: "/rtl",
  //   path: "rtl",
  //   icon: <MdHome className="h-6 w-6" />,
  //   component: <RTLDefault />,
  // },
];
export default routes;
