import ColumnsTable, { RowObj } from "./components/ColumnsTable";
import { historicalDataAPI,getOptionListAPI } from "api";
import { useEffect, useState, ChangeEvent } from 'react';
import InputField from "components/fields/InputField";
import chroma from 'chroma-js';
import { MouseEvent } from 'react';
import Calendar from "react-calendar";
import 'react-select-search/style.css'
import "react-calendar/dist/Calendar.css";
import Select, {MultiValue,StylesConfig } from 'react-select';
import optionList from 'data/option_list.json'
interface listObjectType {
  _id:string
}

const Tables = () => {
  const [tableData, setTableData] = useState<Array<RowObj>>([])
  const [raceCourse, setRaceCourse] = useState<MultiValue<OptionType>>([])
  const [raceType, setRaceType] = useState<MultiValue<OptionType>>([])
  const [stateAU, setStateAU] = useState<MultiValue<OptionType>>([])
  const [fromDate, onFromDateChange] = useState<Date>(new Date("2022-04-01"))
  const [toDate, onToDateChange] = useState<Date>(new Date())
  const [isLoading, setLoading] = useState<boolean>(false)
  const [trackType, setTrackType] = useState<MultiValue<OptionType>>([])
  const [selections, setSelections] = useState<MultiValue<OptionType>>([])
  const [horseRunning, setHorseRunning] = useState<MultiValue<OptionType>>([])
  const [trackGTELength, setTrackGTELength] = useState<number>(0)
  const [trackLTELength, setTrackLTELength] = useState<number>(0)
  const [raceCourseTypeGroup, setRaceCourseTypeGroup] = useState<string>("group")
  const [ppOver, setPPOverChange] = useState<number>(0)
  const [ppUnder, setPPUnderChange] = useState<number>(0)
  const [ctOver, setCTOverChange] = useState<number>(0)
  const [ctUnder, setCTUnderChange] = useState<number>(0)
  const [rstOver, setRSTOverChange] = useState<number>(0)  
  const [rstUnder, setRSTUnderChange] = useState<number>(0)
  const [wgtOver, setWgtOverChange] = useState<number>(0)
  const [wgtUnder, setWgtUnderChange] = useState<number>(0)
  const [level, setLevelChange] = useState<number>(0)
  const [margin, setMarginChange] = useState<number>(0)
  interface OptionType {
    value: string;
    label: string;
  }
  interface ColourOption {
    readonly value: string;
    readonly label: string;
    readonly color: string;
    readonly isFixed?: boolean;
    readonly isDisabled?: boolean;
  }
  
  const colourOptions: OptionType[] = [
    { value: 'MDN', label: 'MDN'},
    { value: 'CL1', label: 'CL1'},
    { value: 'CL2', label: 'CL2'},
    { value: 'CL3', label: 'CL3'},
    { value: 'CL4', label: 'CL4'},
    { value: 'CL5', label: 'CL5'},
    { value: 'CL6', label: 'CL6'},
    { value: '2U', label: '2U'},
    { value: '3O', label: '3O'},
    { value: 'FM', label: 'FM'},
    { value: 'BM34-70', label: 'BM34-70'},
    { value: 'BM71-90', label: 'BM71-90'},
    { value: 'HDL & STLE', label: 'HDL & STLE'},
    { value: 'GROUPS', label: 'GROUPS'},
  ];
  
  const selectionOptions : OptionType[]  = optionList.horseRunningList

  const stateOptions : OptionType[]  = optionList.stateList
  const raceCourseOptions : OptionType[]  = optionList.raceCourseList
  const raceTypeOptions : OptionType[]  = optionList.raceTypeList
  const trackTypeOptions : OptionType[]  = optionList.trackTypeList
  const horseRunningOptions : OptionType[]  = optionList.horseRunningList

  const getColor  = (value: string) => {
    let color: string = "#333333"
    // 1. filter for "MDN"
    if (value.indexOf("MDN") >=0 ){
      color = "#FF0000"
      return color
    }

    if( value.indexOf("CL1") >=0) {
      color="#0000FF"
      return color
    }

    if( value.indexOf("CL2") >=0) {
      color="#00FF00"
      return color
    }

    if( value.indexOf("CL3") >=0) {
      color="#4B0082"
      return color
    }

    if( value.indexOf("CL4") >=0) {
      color="#FFA500"
      return color
    }

    if( value.indexOf("CL5") >=0) {
      color="#800080"
      return color
    }

    if( value.indexOf("CL5") >=0) {
      color="#FFC0CB"
      return color
    }

    if( value.indexOf("CL7") >=0) {
      color="#A52A2A"
      return color
    }

    if( value.indexOf("2U") >=0) {
      color="#00FFFF"
      return color
    }

    if( value.indexOf("3O") >=0) {
      color="#FF00FF"
      return color
    }
    
    if( value.indexOf("FM") >=0) {
      color="#008080"
      return color
    }

    if( value.indexOf("BM") >=0) {
      color="#40E0D0"
      return color
    }

    if( value.indexOf("OPEN") >=0) {
      color="#800000"
      return color
    }

    if( value.indexOf("HDL") >=0) {
      color="#000080"
      return color
    }

    if( value.indexOf("G") >=0) {
      color="#FFD700"
      return color
    }
    return color
  } 
  const colourStyles: StylesConfig<OptionType, true> = {
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    
      const dataColor = getColor(data.value)

      // 1. Filter for "MDN"
      

      const color = chroma(dataColor);
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? dataColor
          : isFocused
          ? color.alpha(0.1).css()
          : undefined,
        color: isDisabled
          ? '#ccc'
          : isSelected
          ? chroma.contrast(color, 'white') > 2
            ? 'white'
            : 'black'
          : dataColor,
        cursor: isDisabled ? 'not-allowed' : 'default',
  
        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
              ? dataColor
              : color.alpha(0.3).css()
            : undefined,
        },
      };
    },
    multiValue: (styles, { data }) => {
      const dataColor = getColor(data.value)
      const color = chroma(dataColor);
      return {
        ...styles,
        backgroundColor: color.alpha(0.1).css(),
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color:  getColor(data.value),
    }),
    multiValueRemove: (styles, { data }) => ({
    
      ...styles,
      color:  getColor(data.value),
      ':hover': {
        backgroundColor:  getColor(data.value),
        color: 'white',
      },
    }),
  };

  const handleTrackGTELengthChange = (e:ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setTrackGTELength(parseFloat(e.currentTarget.value || "0"))
  }

  const handleTrackLTELengthChange = (e:ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setTrackLTELength(parseFloat(e.currentTarget.value || "0"))
  }

  const handleGroupChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRaceCourseTypeGroup(e.currentTarget.value)
    setRaceType([])
    console.log(raceCourseTypeGroup)
  }

  
    const handlePPOverChange = (e:ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setPPOverChange(parseFloat(e.currentTarget.value || "0"))
    }
    const handlePPUnderChange = (e:ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setPPUnderChange(parseFloat(e.currentTarget.value || "0"))
    }
    const handleCTOverChange = (e:ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setCTOverChange(parseFloat(e.currentTarget.value || "0"))
    }
    const handleCTUnderChange = (e:ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setCTUnderChange(parseFloat(e.currentTarget.value || "0"))
  }
  const handleRSTOverChange = (e:ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setRSTOverChange(parseFloat(e.currentTarget.value || "0"))
    }
    const handleRSTUnderChange = (e:ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setRSTUnderChange(parseFloat(e.currentTarget.value || "0"))
    }
    const handleWgtOverChange = (e:ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setWgtOverChange(parseFloat(e.currentTarget.value || "0"))
    }
    const handleWgtUnderChange = (e:ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setWgtUnderChange(parseFloat(e.currentTarget.value || "0"))
    }
    const handleMarginChange = (e:ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setMarginChange(parseFloat(e.currentTarget.value || "0"))
    }
    const handleLevelChange = (e:ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setMarginChange(parseFloat(e.currentTarget.value || "0"))
    }

  const getData = () => {
    const data = {
      selections: selections?.map(el=>Number(el.value)),
      racecourse: raceCourse?.map(el=>el.value),
      state:stateAU?.map(el=>el.value),
      raceType:raceType?.map(el=>el.value),
      raceTypeGroup:raceCourseTypeGroup === "group",
      trackType:trackType?.map(el=>el.value),
      horseRunning: horseRunning?.map(el=>Number(el.value)),
      from: fromDate.toISOString().split('T')[0] || new Date().toISOString().split('T')[0],
      to:toDate.toISOString().split('T')[0] || new Date().toISOString().split('T')[0],
      trackGTELength:trackGTELength,
      trackLTELength: trackLTELength,
      ppOver: ppOver,
      ppUnder: ppUnder,
      ctUnder: ctUnder,
      ctOver: ctOver,
      rstUnder: rstUnder,
      rstOver: rstOver,
      wgtOver: wgtOver,
      wgtUnder: wgtUnder,
      level: level,
      margin: margin,
    }
    setLoading(true)
    historicalDataAPI(data).then(res=> {
      setTableData(res.selectionList)
      setLoading(false)
      console.log(res)
    }).catch(err => {
      console.log(err)
    })
  }

  return (
    <div>
      <div className="mt-5 grid h-full grid-cols-1 gap-5">
        {/* <DevelopmentTable tableData={tableDataDevelopment} /> */}
        {/* <CheckTable tableData={tableDataCheck} /> */}
      </div>
      <div>
        <div className="grid grid-cols-5 gap-2">
          <label className="col-span-1 text-center flex items-center justify-center">Selections:</label>
          <Select 
            value={selections} 
            onChange={setSelections}
            isMulti
            options={selectionOptions} 
            className="col-span-3" 
            />
        </div>
        <div className="mt-2 grid grid-cols-5 gap-2">
          <label className="col-span-1 text-center flex items-center justify-center">Race Course:</label>
          <Select 
            value={raceCourse} 
            onChange={setRaceCourse}
            isMulti
            options={raceCourseOptions} 
            className="col-span-3" 
            />
        </div>
        <div className="mt-2 grid grid-cols-5 gap-2">
          <label className="col-span-1 text-center flex items-center justify-center">State:</label>
          <Select 
            value={stateAU} 
            onChange={setStateAU}
            isMulti
            options={stateOptions} 
            className="col-span-3" 
            />
        </div>
        <div className="mt-2 grid grid-cols-5 gap-2">
          <label className="col-span-1 text-center flex items-center justify-center">Race Course Type:</label>
          <div className="col-span-3 gap-2 grid grid-cols-4">
            <div className="col-span-1 flex items-center">

              <label>group</label>
              <input type="radio" checked={raceCourseTypeGroup === "group"} value="group" onChange={handleGroupChange} name="raceCourseTypeGroup" className="ml-2"/>
            </div>
            <div className="col-span-1 flex items-center">
              <label>individual</label>
              <input type="radio" checked={raceCourseTypeGroup ==="individual"} value="individual" onChange={handleGroupChange} name="raceCourseTypeGroup" className="ml-2"/>
            </div>
          </div>
        </div>
        <div className="mt-2 grid grid-cols-5 gap-2">
          <label className="col-span-1 text-center flex items-center justify-center"></label>
          

          <Select 
            value={raceType} 
            onChange={setRaceType}
            isMulti
            styles={colourStyles}
            options={raceCourseTypeGroup === "group" ?colourOptions: raceTypeOptions}
            // options={ColourOption} 
            className="col-span-3" 
            />
        </div>
        <div className="mt-2 grid grid-cols-5 gap-2">
          <label className="col-span-1 text-center flex items-center justify-center">Track Type:</label>
          <Select 
            value={trackType} 
            onChange={setTrackType}
            isMulti
            options={trackTypeOptions} 
            className="col-span-3" 
            />
        </div>
        <div className="mt-2 grid grid-cols-5 gap-2">
          <label className="col-span-1 text-center flex items-center justify-center">Horse Running:</label>
          <Select 
            value={horseRunning} 
            onChange={setHorseRunning}
            isMulti
            options={horseRunningOptions} 
            className="col-span-3" 
            />
        </div>

        <div className="mt-3 grid grid-cols-2 bg-white gap-5 p-5 rounded-lg">
          <div className="col-span-1">
            From Date:
            <Calendar  onChange={onFromDateChange} value={fromDate}/>
          </div>
          <div className="col-span-1">
            To Date:
            <Calendar  onChange={onToDateChange} value={toDate}/>
          </div>
        </div>
        <div className="mt-5 grid h-full grid-cols-7 gap-5 bg-white p-5 rounded-lg" >
          {/* FromSelection */}
          <InputField
            variant="auth"
            extra="mb-3"
            label="Length Over:"
            placeholder="ex: 1"
            id="gteLength"
            state="success"
            type="number"
            onChangeHandler={handleTrackGTELengthChange}
          />
          {/* ToSelection */}
          <InputField
            variant="auth"
            extra="mb-3"
            label="Length Under:"
            placeholder="ex: 5"
            id="lteLength"
            state="success"
            type="number"
            onChangeHandler={handleTrackLTELengthChange}
          />
          {/* FromSelection */}
                <InputField
                    variant="auth"
                    extra=""
                    label="PP Over:"
                    placeholder="ex: 1"
                    id="ppOver"
                    state="success"
                    type="number"
                    onChangeHandler={handlePPOverChange}
                />
                {/* ToSelection */}
                <InputField
                    variant="auth"
                    extra="mb-3"
                    label="PP Under:"
                    placeholder="ex: 5"
                    id="ppUnder"
                    state="success"
                    type="number"
                    onChangeHandler={handlePPUnderChange}
                />
                {/* FromSelection */}
                <InputField
                    variant="auth"
                    extra="mb-3"
                    label="Weight Over:"
                    placeholder="ex: 1"
                    id="wgtOver"
                    state="success"
                    type="number"
                    onChangeHandler={handleWgtOverChange}
                />
                {/* ToSelection */}
                <InputField
                    variant="auth"
                    extra="mb-3"
                    label="Weight Under:"
                    placeholder="ex: 5"
                    id="wgtUnder"
                    state="success"
                    type="number"
                    onChangeHandler={handleWgtUnderChange}
                />
                {/* FromSelection */}
                <InputField
                    variant="auth"
                    extra="mb-3"
                    label="CT Over:"
                    placeholder="ex: 1"
                    id="ctOver"
                    state="success"
                    type="number"
                    onChangeHandler={handleCTOverChange}
                />
                {/* ToSelection */}
                <InputField
                    variant="auth"
                    extra="mb-3"
                    label="CT Under:"
                    placeholder="ex: 5"
                    id="ctUnder"
                    state="success"
                    type="number"
                    onChangeHandler={handleCTUnderChange}
          />
          {/* FromSelection */}
                <InputField
                    variant="auth"
                    extra="mb-3"
                    label="RST Over:"
                    placeholder="ex: 1"
                    id="rstOver"
                    state="success"
                    type="number"
                    onChangeHandler={handleRSTOverChange}
                />
                {/* ToSelection */}
                <InputField
                    variant="auth"
                    extra="mb-3"
                    label="RST Under:"
                    placeholder="ex: 5"
                    id="rstUnder"
                    state="success"
                    type="number"
                    onChangeHandler={handleRSTUnderChange}
                        />
              {/* FromSelection */}
                <InputField
                    variant="auth"
                    extra="mb-3"
                    label="Level:"
                    placeholder="ex: 1"
                    id="level"
                    state="success"
                    type="number"
                    onChangeHandler={handleLevelChange}
                />
                {/* FromSelection */}
                <InputField
                    variant="auth"
                    extra="mb-3"
                    label="Margin:"
                    placeholder="ex: 1"
                    id="margin"
                    state="success"
                    type="number"
                    onChangeHandler={handleMarginChange}
                />
          <div className="col-span-1 flex items-center justify-center">
            <button onClick={getData} className="bg-[#4318ff] text-white rounded-lg hover:bg-[#3290ff] hover:shadow-md h-[50%] w-[60%]">Get Data</button>
          </div>
        </div>
      </div>
      {/* <Calendar  selectRange={true}/> */}
      {
        tableData.length > 0 && !isLoading ? 
        <>
          <div className="mt-5 grid h-full grid-cols-1 gap-5">
            <ColumnsTable tableData={tableData}  />

            {/* <ComplexTable tableData={tableDataComplex} /> */}
          </div>
        </>
        :
        <>
          <div className="mt-5 h-[300px] flex items-center justify-center">{isLoading?"Loading data..":"No Table data"}</div>
        </>
      }
    </div>
  );
};

export default Tables;
