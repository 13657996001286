import { fetchAPI, downloadFetchAPI } from "./base"
import { 
    signUpAPIProps,
    verifyAccountAPIProps,
    loginAPIProps,
    hisotricalDataAPIProps,
    liveRaceDetailAPIProps
} from "./props"

//-------------------- Auth API ----------------------//
export const signUpAPI = (data:signUpAPIProps) => fetchAPI('/auth/signup', data, "POST")
export const verifyAccountAPI = (data:verifyAccountAPIProps) => fetchAPI('/auth/verify', data, "PATCH")
export const loginAPI = (data:loginAPIProps) => fetchAPI('/auth/login', data, "POST")
export const historicalDataAPI = (data:hisotricalDataAPIProps) => fetchAPI('/data/historical-data', data, "POST")
export const racecourseAPI = (data:hisotricalDataAPIProps) => fetchAPI('/data/racecourse', data, "POST")
export const liveRacesAPI = (data: hisotricalDataAPIProps) => fetchAPI('/data/liveraces', data, "POST")
export const prevRacesAPI = (data: hisotricalDataAPIProps) => fetchAPI('/data/prevraces', data, "POST")
export const liveRaceDetailAPI = (data:liveRaceDetailAPIProps) => fetchAPI('/data/liverace-detail', data, "POST")
export const prevRaceDetailAPI = (data:liveRaceDetailAPIProps) => fetchAPI('/data/prevrace-detail', data, "POST")
export const postLogoutAPI = () => fetchAPI('/auth/logout', {}, "POST")
export const getOptionListAPI = () => fetchAPI('/DATA/option-list', {}, "GET")
export const getRawDataAPI = (data: any) => downloadFetchAPI('/data/raw-data', data)